const BACKEND = process.env.REACT_APP_HOME || 'http://localhost:9000';
const path = window.location.pathname;
const params = new URLSearchParams(window.location.search);
const userAgent = encodeURIComponent(window.navigator.userAgent);

const utmSource = params.get('utm_source') || 'accuweather-landing';
const utmMedium = params.get('utm_medium') || 'organic';
const utmCampaign = params.get('utm_campaign') || 'no_campaign';
const utmTerm = params.get('utm_term') || 'no_terms';
const utmContent = params.get('utm_content') || 'no_content';

const pixelUrl = `https://track.mocha.global/?timestamp=${Date.now()}ua=${userAgent}&url=${encodeURIComponent(
    'https://mb.accuweather.com'
)}&pixel_id=iKdooUo9B1H2ThhYgRf8WH&campaign_id=vWzCtQW1G6dkQ4yBXyH1sr&campaign_type=supply&e=accuweather-landing&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_term=${utmTerm}&utm_content=${utmContent}`;

const pixel = document.createElement('img');
pixel.src = pixelUrl;
document.querySelector('body').appendChild(pixel);

window.localStorage &&
    window.localStorage.setItem(
        'tyUrl',
        `${BACKEND}/thank-you?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_term=${utmTerm}&utm_content=${utmContent}`
    );

const getExtensionStoreUrl = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const browsers = ['edg', 'chrome', 'firefox'];
    const supportedBrowsersFullNames = {
        chrome: 'Chrome',
        firefox: 'Firefox',
        edg: 'Edge',
    };

    const browser = supportedBrowsersFullNames[browsers.find(browser => userAgent.search(browser) > -1)];

    switch (browser) {
        case 'Chrome':
            return 'https://chrome.google.com/webstore/detail/weather-forecast-powered/conoiojhfhpoboccndegeemkpgkcnkoe';
        case 'Firefox':
            return 'https://addons.mozilla.org/en-US/firefox/addon/weather-powered-by-accuweather';
        case 'Edge':
            return 'https://microsoftedge.microsoft.com/addons/detail/lcpfjmblaenhkgmejbafmemkgecheono';
        default:
            return 'https://chrome.google.com/webstore/detail/weather-forecast-powered/conoiojhfhpoboccndegeemkpgkcnkoe';
    }
};

if (path.includes('lp.php')) {
    const url = getExtensionStoreUrl();

    window.location.replace(url);
}
